import React from 'react'


const DevProcess = () => {


  return (
    <section className="customs payment pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Development Process</span> Of Our Crypto Payment Gateway
            </h2>
            <p className='text-center'>Creating a crypto payment gateway with our team is Transparent and efficient. We guide you through every step, from initial consultation to final deployment. Our expertise in payment gateway software development ensures that you receive a solution that is both innovative and reliable.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Ideation</h3>
                    <p class="pharagraph">Our team gathers your choice, ideas, and concepts for crypto payment gateway creation. We conceptualize the step-by-step development process as per your choice. We prioritize the legal aspects of creating a potential crypto payment gateway.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Resource Gathering</h3>
                    <p class="pharagraph">We focus on gathering the necessary tech stack, development tools, and utilities before the development phase. Our special crypto payment gateway developers assist you in analyzing the required resources before the designing process.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Designing Blockchain Payment System</h3>
                    <p class="pharagraph">Our design team contributes their best to get your choice of UI/UX design outcome for your crypto transaction platform. We focus on offering a prominent theme, display options, a user-friendly interface, and easy navigation that seeks the attention of crypto users.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Payment Gateway Development</h3>
                    <p class="pharagraph">Our previous experience is your guide for easily developing the cryptocurrency payment gateway development platform. With our experienced developers, technicians, and experts you can get the exact platform outcome of your ideation.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Debugging</h3>
                    <p class="pharagraph">We clear out the errors, technical bugs, unnecessary website content, and server errors that are rectified before the platform launch. As much as testing is conducted potentially, we can get a flawless platform for crypto transactions.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Payment Processor Platform Launch</h3>
                    <p class="pharagraph">After the testing process, you can launch the cryptocurrency payment gateway into the crypto market. Even after launching, our team of crypto payment gateway experts helps you with upgrading, monitoring, and error-correcting processes.</p>
                </div>
            </div>
        </div>
      </div>
    </section>
  )
}

export default DevProcess