import React from 'react'



class PaymentOption extends React.Component {

  render() {

    return (
      <div className='pt-100'>
      <section className="secureof lucrative">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Payment Options </span>available through Our Crypto Payment Gateway Development</h2>
          <div className="pay-option">
            <div className="revenue">
              <p>Bitcoin (BTC)</p>
              <p>Bitcoin Cash (BCH)</p>
              <p>Ethereum (ETH)</p>
            </div>
            <div className="revenue">
              <p>Solana (SOL)</p>
              <p>Ripple (XRP)</p>
              <p>USD coin (USDC)</p>
            </div>
            <div className="revenue">
              <p>Gemini USD (GUSD)</p>
              <p>Celo (CELO)</p>
              <p>Litecoin (LTC)</p>
            </div>
            <div className="revenue">
              <p>Dogecoin</p>
              <p>Zcash (ZEC)</p>
              <p>Binance USD (BUSD)</p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default PaymentOption