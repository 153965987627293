import React from 'react'



class Useradmin extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="pick pt-100 secureof">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Key Features</span> of our Crypto Payment Gateway Development Solution</h2>
          <p className="text-center">At Coinsclone, our certified Crypto payment gateway developers incorporate elite features to craft a highly potential crypto payment gateway software.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv mb-0'>
                <h3>User Features</h3>
                <ul className='mb-0'>
                <li><p>User Sign-in/ Sign-up</p></li>
                <li><p>My Profile</p></li>
                <li><p>KYC/AML</p></li>
                <li><p>Effective Dashboard</p></li>
                <li><p>Multiple payment options</p></li>
                <li><p>Deposit & Withdraw History</p></li>
                <li><p>Support Ticket Submission</p></li>
                <li><p>Two-factor authentication</p></li>
                <li><p>Merchant Tools</p></li>
                <li><p>Account Settings</p></li>
              </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv mb-0'>
                <h3>Admin Features</h3>
                <ul className='mb-0'>
                <li><p>Comprehensive Admin Dashboard</p></li>
                <li><p>View User Deposit and Withdrawal Information</p></li>
                <li><p>History of Merchant Payments</p></li>
                <li><p>Commission Settings</p></li>
                <li><p>Merchant Details</p></li>
                <li><p>Merchant API Category</p></li>
                <li><p>Security Settings</p></li>
                <li><p>Content Management System</p></li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Useradmin