import React from 'react'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree';



class Pickcrypto extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="pick pt-100 secureof">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Make Profitable Revenue</span> with our Crypto Payment Gateway Development Services</h2>
          <p className="text-center">Earn a significant amount of money by constructing a fantastic crypto payment gateway platform through our ready-to-market payment gateway software.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <h3>Transaction fees</h3>
                <p className="pharagraph">You can charge your users a fee for each successful virtual currency payment and transaction on your platform. All existing crypto payment gateway platforms are pursuing this optimum monetization approach.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <h3>Crypto withdrawal fees</h3>
                <p className="pharagraph">If your users wish to withdraw crypto assets from your platform, you could charge them in the form of crypto. As a platform administrator, you can design an exceptional fee structure for each cryptocurrency withdrawal. You would gain a significant profit even if you simply collect the slightest amount of crypto withdrawal fees.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <h3>Fiat deposit with debit/credit card</h3>
                <p className="pharagraph">For buying goods or services through your platform, your user will need either crypto or fiat currency. To deposit fiat currency, your user can utilize their debit/credit card, or else they can directly deposit funds by connecting their bank account. For this process, you can collect a small range of fees and you can earn a fruitful income easily.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <h3>Crypto listing fees</h3>
                <p className="pharagraph">Once your platform becomes prominent among many people, you can organically boost the overall user count. People talk about your brand and express interest in using your platform for payments. As a result, many megacorps will approach you to list their crypto on your platform. You can charge a fee for doing this from the creator or the respective organization. </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv mb-0'>
                <h3>Merchant fees</h3>
                <p className="pharagraph mb-0">It is one of the ideal revenue-earning methods pursued by many existing crypto payment gateway platforms. As an admin, you can collect fees from the Merchants who utilize your crypto payment processor for billing and payments. Either you can charge monthly or weekly, it’s up to your choice.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv mb-0'>
                <h3>Fiat withdrawal fees</h3>
                <p className="pharagraph mb-0">You can charge a fee when a user or merchant withdraws fiat funds from their account. It is one of the most frequent and profitable forms of income that you can employ in your payment gateway business and generate a substantial amount of revenue.</p>
              </div>
            </div>
          </div>
          <div className="mt-3 text-center">
              <ButtonStartfree />
          </div>
        </div>
      </section>
    )
  }
}

export default Pickcrypto