import React from 'react'


const BenifitsOf = () => {

  return (
    <section className="typeof crypapp pt-100 mb-0">
      <div className='gray-bg'>
        <div className="container">
          <div className="row">
              <h2 className="heading-h2 text-center"><span className="bluecolor">Benefits Of</span> Our Crypto Payment Gateway Development Services
              </h2>
              <p className="text-center">
              We offer intellectual ideas at the finest that reap infinite benefits for your crypto payment gateway development business.
              </p>
          </div>
          <div className='row popular we-offer'>
              <div className='col-md-12 col-lg-3'>
                  <h3>Global Outreach</h3>
                  <p className='pharagraph'>We focus on developing a payment processor platform that attracts the eyes of people all over the world. Our crypto payment gateway development services integrate understandable and user-friendly features for user convenience.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Revenue Generating Streams</h3>
                  <p className='pharagraph'>Crypto payment processors pave the way to reap more profits for the administrators through various means. We help to integrate potential earning streams as per your marketing and business strategies.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Long-lasting Investment</h3>
                  <p className='pharagraph'>You can achieve long-lasting fame and potential popularity with our crypto payment gateway development services. Crypto payment gateway development will be a lucrative business idea since the values are increasing rapidly.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Quick Launch</h3>
                  <p className='pharagraph'>We help to deploy the crypto payment gateway platform with advanced-level development methodologies. With our pre-fabricated white-label software you can launch your digital asset payment platform within 15 days.</p>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BenifitsOf